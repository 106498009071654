/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 20/01/2021
 * @update 29/01/2021
 * @file src/components/header/header.js
 */
import { DateBox } from "devextreme-react";
import { Column, Lookup, Summary, TotalItem } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import _ from "lodash";
import moment from "moment";
import "moment/locale/vi";
import React from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { Input } from "reactstrap";
import { bindActionCreators, compose } from "redux";
import FileDeleteAsync from "../../actions/file-delete-async";
import FileRemoveAsync from "../../actions/file-remove-async";
import FileUploadAsync from "../../actions/file-upload-new-async";
import * as userActions from "../../actions/user-actions";
import * as w17f2000Actions from "../../actions/w17/w17F2000/w17F2000-actions";
import Config from "../../config";
import GridActionBar from "../common/action-bar/grid-action-bar";
import GridActionBarHover from "../common/action-bar/grid-action-bar-hover";
import Combo                 from "../common/dropdown/combo";
import GridContainer         from "../common/grid-container/grid-container";
import { Loading }           from "../common/loading/loading";
import EventTracking         from "../common/tracking/event";
import W18F1015CustomerPopUp from "../W18/W18F1015";
import W17F2020PopUp         from "./W17F2020";
import W17F2030PopUp         from "./W17F2030";
import ButtonExport          from "../common/button/button-export";
import W17F2035PopUp from "./W17F2035";
import W18F1014 from "../W18/W18F1014";
import * as w18F1014Actions from "../../actions/w18F1014-actions";
import PopupScroll from "../popup/popup-scroll";
import PropTypes from "prop-types";
import { Row, Col, Label as LabelCore, Button, Typography, Image, ButtonIcon, Collapse } from 'diginet-core-ui/components';
import { makeStyles } from "diginet-core-ui/theme";

const useStyles = makeStyles((theme) => ({
    dropdownIconCb: {
        position: "absolute",
        top: 8,
        right: 16,
        pointerEvents: "none",
        padding: 'inherit',
    },
    gridContainer: {
        '& .grid-container': {
            paddingTop: '0 !important',
        }
    },
    inputPopup: {
        backgroundColor: 'initial !important'
    },
    customGridCss: {
        '& td': {
            '& .dx-highlight-outline': {
                height: '33px',
            }
        }
    },
    textCombo: {
        '&.dx-state-disabled': {
            opacity: '1 !important',
            '& div.dx-texteditor-buttons-container': {
                visibility: 'hidden',
                opacity: 0
            }
        }
    },
    inputText: {
        backgroundColor: 'transparent !important',
        '& input': {
            backgroundColor: 'transparent !important',
        }
    },
    noteText: {
        '& textarea': {
            backgroundColor: 'transparent !important',
        }
    },
    numberFormatInput: {
        backgroundColor: 'transparent',
        border: 'none',
        margin: 0,
        padding: theme.spacing(2)
    },
    imgAttachment: {
        '& > img': {
            marginBottom: 0,
            objectFit: 'cover',
            maxWidth: '70px !important'
        }
    },
    otherAttachment: {
        '& > img': {
            maxWidth: '70px !important'
        }
    },
}));
const Label = (props) => <LabelCore type={"h4"} {...props}/> ;
const FormID = "W17F2000";

class W17F2000Page extends React.PureComponent {
    constructor(props) {
        super(props);
        const { mode } = this.getInfo();
        const { dataCboCurrency, dataCboDivision, dataCboEmployee } = this.props;
        // exchangeRate
        this.state = {
            loading: false,
            collapse: true,
            saveSuccess: false, // lưu thành công disabled Form
            showW17F2020: false,
            showW18F1015: false,
            showW17F2030: false,
            showW17F2035: false,
            popupClose: {
                open: false,
                data: {}
            },
            iPerD05F1611: 0,
            iPerD05F1632: 0,
            iPerD05F5620: 0,
            iPerD05F5625: 0,
            aStatus: 0,
            vatDisplay: 1, // 1 = Hiện 0 = Ẩn
            isQuotationNoneCode: 0, //  = 0 cho edit mã hàng
            mode: mode,
            objectID: "",
            inventory: "",
            objectTypeID: "",
            objectAddress: "",
            quotationID: "",
            objectName: "",
            priceListID: "",
            paymentTermID: "",
            paymentMethodID: "",
            deliveryMethodID: "",
            voucherTypeID: "",
            statusVoucher: "",
            approveNote: "",
            approveUserName: "",
            approveDate: "",
            voucherNum: "",
            tranTypeID: "",
            contactPerson: "",
            shipAddressID: "",
            shipAddress: "",
            shipDateNum: 0,
            linkMaster: "",
            linkDetail: "",
            currencyID: !_.isEmpty(dataCboCurrency) && mode === "add" ? dataCboCurrency[0].ID : "", // Default giá trị mặt định đầu tiên
            exchangeRate: !_.isEmpty(dataCboCurrency) && mode === "add" ? dataCboCurrency[0].ExchangeRate : "", // Default giá trị mặt định đầu tiên
            divisionID: !_.isEmpty(dataCboDivision) && mode === "add" ? dataCboDivision[0].DivisionID : "", // Default giá trị mặt định đầu tiên
            employeeID: this.getDefaultEmployeeID(!_.isEmpty(dataCboEmployee) && mode === "add" ? dataCboEmployee[0].EmployeeID : ""), // Default giá trị mặt định đầu tiên
            validFrom: null,
            validTo: null,
            voucherDate: moment().format("YYYY-MM-DD"), // Default Date
            telephone: null,
            currencyObj: {},
            attached: [],
            dataGrid: [],// grid tam chi chua nhung dong duoc add vao
            dataAttach: [],
            selectedRow: [],
            dynamicColumm: [],
            priceListData: [],
            dynamicColummName: [],
            showW18F1014: false,
            isClosed: 0,
            closingUserName: "",
            closingDate: "",
            closingReason: "",
        };
        this.usePriceList = 0;
        this.initialState = { ...this.state }; // Sate ban đầu
        this.fromDate = null;
        this.gridContainer = null;
        this.OldAttachData = [];
        this.Language = Config.language || "84";
        this.dataW18F1014 = { rowData: {}, mode: 'add', unRedirect: true };
        this.isLoadForm = false;
        this.defaultDynamicCol = {};
    };

    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    };

    componentDidMount = async () => {
        if (!this.getInfo()) return;
        const { mode, isLoadContactPerson, ObjectID, objectCustomer, isLoadForm } = this.getInfo();
        const { dataCboStatus, dataCboTranType, dataDMFSetting,
            dataCboDeliveryMethod, dataCboVATGroup, dataCboPaymentTerm, dataCboPaymentMethod, } = this.props;
        this.loadPermission();
        if (mode !== "add" || isLoadForm) await this.loadForm();
        if (!_.isEmpty(objectCustomer)) {
            this.setState({
                objectID: objectCustomer.objectID ? objectCustomer.objectID : "",
                objectName: objectCustomer.objectName ? objectCustomer.objectName : "",
                objectTypeID: objectCustomer.objectTypeID ? objectCustomer.objectTypeID : "",
                telephone: objectCustomer.telephone ? objectCustomer.telephone : "",
                paymentTermID: objectCustomer.paymentTermID ? objectCustomer.paymentTermID : "",
                divisionID: objectCustomer.divisionID ? objectCustomer.divisionID : ""
            }, () => {
                if (isLoadContactPerson) this.getCboContactPerson({ ObjectID }, true); // Load Loại Nghiệp Vụ và cho default khách hàng
            })
        }
        this.getComboData("getCboDivision");
        this.getComboData("getCboCurrency");
        this.getComboData("getCboEmployee");
        this.getComboData("getCboPacking");
        this.getComboData("getCboShipAddress", {ObjectID: ObjectID || objectCustomer?.objectID || ""});
        if (_.isEmpty(dataCboTranType) || mode === "add") this.getComboData("getCboTranType");
        if (_.isEmpty(dataCboDeliveryMethod)) this.getComboData("getCboDelivery");
        if (_.isEmpty(dataCboVATGroup)) this.getComboData("getCboVATGroup");
        if (_.isEmpty(dataCboPaymentTerm)) this.getComboData("getCboPaymentTerm");
        if (_.isEmpty(dataCboPaymentMethod)) this.getComboData("getCboPaymentMethod");
        if (_.isEmpty(dataCboStatus)) this.getComboData("getCboStatus");
        if (_.isEmpty(dataDMFSetting)) this.getComboData("getDmfSetting");
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { tranTypeID, paymentMethodID, paymentTermID, currencyID} = this.state;
        if (prevState.mode !== this.state.mode) {
            this.getComboData("getCboEmployee");
        }
        if (this.state.objectID !== prevState.objectID) {
            this.getComboData("getCboShipAddress", { ObjectID: this.state.objectID });
        }
        if (prevState.tranTypeID !== this.state.tranTypeID) {
            const _tranType = this.props.dataCboTranType?.find(d => d.TranTypeID === this.state.tranTypeID);
            if (_tranType) this.usePriceList = _tranType?.UsePriceList || 0;
        }
        if ( prevState.currencyID !== this.state.currencyID || prevProps.dataCboCurrency?.length !== this.props.dataCboCurrency?.length ) {
            const currencyObj = this.props.dataCboCurrency?.find(d => d.ID === this.state.currencyID);
            if (currencyObj) this.setState({currencyObj: currencyObj});
        }
        if (prevState?.tranTypeID !== tranTypeID
            || prevState?.paymentMethodID !== paymentMethodID
            || prevState?.paymentTermID !== paymentTermID
            || prevState?.currencyID !== currencyID) {
            this.loadCboPriceList();
        }
    }

    loadPermission = async () => {
        await this.props.userActions.getPermission(Config.profile.UserID, ["D05F1611", "D05F1632", "D05F5620", "D05F5625"], (arrPer) => {
            if (arrPer) {
                const iPerD05F1611 = arrPer.find(data => data.ScreenID === "D05F1611");
                const iPerD05F1632 = arrPer.find(data => data.ScreenID === "D05F1632");
                const iPerD05F5620 = arrPer.find(data => data.ScreenID === "D05F5620");
                const iPerD05F5625 = arrPer.find(data => data.ScreenID === "D05F5625");
                this.setState({
                    iPerD05F1611: (iPerD05F1611 && iPerD05F1611.Permission) || 0,
                    iPerD05F1632: (iPerD05F1632 && iPerD05F1632.Permission) || 0,
                    iPerD05F5620: _.get(iPerD05F5620, "Permission", 0),
                    iPerD05F5625: (iPerD05F5625 && iPerD05F5625.Permission) || 0,
                });
            }
        });
    };

    getDefaultEmployeeID = (defaultValue = "") => {
        let _employeeID = Config.profile.SalesPersonID || "";
        if (!_employeeID) {
            const user = Config.getUser({UserID: Config.profile.UserID});
            _employeeID = user.SalesPersonID || "";
        }
        return _employeeID || defaultValue || "";
    };

    getInfo = (flag) => {
        const { location } = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if ((location && location.state) || (url && url.get('QuotationID'))) {
            return {
                id: location.state?.id || location.state?.QuotationID || url.get('QuotationID'),
                mode: location.state?.mode || 'view',
                formCall: location.state?.formCall || "",
                ObjectID: location.state?.ObjectID || "",
                RequestID: location.state?.RequestID || "",
                objectCustomer: location.state?.objectCustomer || {},
                isTabRequest: location.state?.isTabRequest || false, // Load lại Form
                isLoadContactPerson: location.state?.isLoadContactPerson || false, // Load ContactPerson
                isLoadForm: location.state?.isLoadForm || false, // Load ContactPerson
                currentTabKey: location.state?.currentTabKey || "",
                formCallState: location.state?.formCallState || {},
                IsCoppy: location.state?.IsCoppy || 0,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + "/W19F1000");
                return null;
            }
            return false;
        }
    };

    setDefaultValueCbo = (apiComboName = "", dataCbo = []) => {
        if (_.isEmpty(apiComboName) || _.isEmpty(dataCbo)) return;
        let value = "";
        let stateName = "";
        let valuePlus = "";
        let stateNamePlus = "";
        let status = true;
        switch (apiComboName) {
            case "getCboDivision":
                stateName = "divisionID";
                value = dataCbo[0].DivisionID || "";
                break;
            case "getCboCurrency":
                status = false;
                stateName = "currencyID";
                value = dataCbo[0].ID || "";
                stateNamePlus = "exchangeRate";
                valuePlus = dataCbo[0].ExchangeRate || "";
                break;
            case "getCboEmployee":
                stateName = "employeeID";
                value = this.getDefaultEmployeeID(dataCbo[0].EmployeeID);
                break;
            case "getCboTranType":
                stateName = "tranTypeID";
                value = dataCbo[0].TranTypeID || "";
                break;
            case "getCboShipAddress":
                status = false;
                stateName = "shipAddressID";
                stateNamePlus = "shipAddress";
                value = dataCbo[0].ShipAddressID || "";
                valuePlus = dataCbo[0].ShipAddress || "";
                break;
            default:
                break;
        }
        if (_.isEmpty(this.state[stateName])) { // Trống thì ta mới set default
            status ? this.setState({ [stateName]: value }) : this.setState({ [stateName]: value, [stateNamePlus]: valuePlus });
        }
    };

    getComboData = (apiComboName, params) => {
        const defaultValueCboName = ["getCboDivision", "getCboCurrency", "getCboEmployee", "getCboShipAddress"];
        if (apiComboName) {
            if (apiComboName === "getCboEmployee") {
                params = {
                    Language: Config.language || "84",
                    FormID,
                    DataType: "CmbSalesPerson",
                    Mode: this.state.mode === "add" ? "A" : (this.state.mode === "edit" ? "E" : "V")
                };
            }
            if (apiComboName === "getCboPacking") {
                params = {
                    Language: Config.language || "84",
                    FormID,
                    DataType: "LoadPacking"
                };
            }
            if (params) {
                this.props.w17f2000Actions[apiComboName](params, async (error, data) => {
                    if (error) {
                        const message = error.message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    } else if (data && this.state.mode === "add") {
                        if (defaultValueCboName.includes(apiComboName)) {
                            this.setDefaultValueCbo(apiComboName, data);
                        }
                    }
                })
            } else {
                this.props.w17f2000Actions[apiComboName](async (error, data) => {
                    if (error) {
                        const message = error.message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    } else if (data && this.state.mode === "add") {
                        if (defaultValueCboName.includes(apiComboName)) {
                            this.setDefaultValueCbo(apiComboName, data);
                            if (apiComboName === "getCboCurrency" && !_.isEmpty(this.props.dataCboCurrency)) this.loadCboPriceList(); // Load CBO Bảng giá
                        } else if (apiComboName === "getCboTranType" && data.length === 1) { //Nếu chỉ có 1 data thì mới set mặc định nghiệp vụ, voucher
                            const {TranTypeID = ""} = data[0];
                            this.onChangeVoucher(TranTypeID, false, data[0]);
                        }
                    }
                })
            }
        }
    }

    loadCboPriceList = () => {
        const { currencyID: CurrencyID = "", tranTypeID: TranTypeID = "", paymentMethodID: PaymentMethodID = "", paymentTermID: PaymentTermID = "", priceListID: priceListIDMaster = ""  } = this.state || {};
        const param = {
            FormID,
            DataType: "CmbPriceList",
            TranTypeID,
            PaymentMethodID,
            PaymentTermID,
            CurrencyID
        }
        this.props.w17f2000Actions.loadPriceList(param, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                let priceListData = data;
                let priceListID = this.isLoadForm ? priceListIDMaster : "";
                if (!_.isEmpty(data)) {
                    priceListData = data.map(item => {
                        const line = !_.isEmpty(item.DescriptionU) ? "-" : "";
                        if (item?.IsDefault === 1 && !priceListID && !this.isLoadForm) {
                            priceListID = item?.PriceListID || ""; // set default
                        }
                        return ({ ...item, PriceListCustomName: `${item.PriceListID || ""} ${line} ${item.DescriptionU || ""}` })
                    });
                }
                this.isLoadForm = false;
                this.setState({ priceListData, priceListID }); //clear old value
            }
        })
    }

    getCboContactPerson = (params, setDefaultData = false) => {
        this.props.w17f2000Actions.getCboContactPerson(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                if (setDefaultData && !_.isEmpty(data)) {
                    this.setState({
                        dataCboContactPerson: data,
                        contactPerson: data[0].ContactPerson,
                        telephone: data[0].Telephone
                    });
                } else {
                    this.setState({ dataCboContactPerson: data });
                }
            }
        })
    }

    deleteRow = (e) => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            let grid = [...this.state.dataGrid];
            grid.splice(e.rowIndex, 1);
            const selectedRow = grid.filter(rs => rs.InventoryID !== '').map(item => item.InventoryID);
            this.setState({
                dataGrid: grid,
                selectedRow
            });
        })
    }

    hideW18F1015 = async (data) => {
        if (!data) {
            this.setState({ showW18F1015: false });
            return false;
        }
        let setDefaultContactPerson = false;
        if (this.state.mode === "add") setDefaultContactPerson = true;
        this.setState({
            showW18F1015: false,
            objectID: data.ObjectID,
            objectName: data.CompanyNameU,
            objectTypeID: data.CompanyTypeID,
            objectAddress: data.ObjectAddress || "",
            telephone: data.Telephone ? data.Telephone : null,
            paymentTermID: data.PaymentTermID ? data.PaymentTermID : null,
            divisionID: data.DivisionID ? data.DivisionID : this.state.divisionID,
            shipAddressID: "",
            shipAddress: "",
            shipDateNum: 0,
            employeeID: data.SalesPersonID || this.getDefaultEmployeeID()
        }, () => { // Load lại combo Contact Person
            if (data.ObjectID) {
                this.getCboContactPerson({ ObjectID: data.ObjectID, setDefaultContactPerson });
                this.getComboData("getCboShipAddress", {ObjectID: data.ObjectID});
                document.getElementById("objectAddressW17F2000").value = data.ObjectAddress || "";
            }
        })
    };

    hideW17F2020 = () => {
        this.setState({ showW17F2020: false });
    };

    calculate = (rowData = {}, columnName = "", e) => {
        let rowIndex = -1;
        const { dataCboVATGroup } = this.props;
        let vatRateResult = Number.isInteger(rowData.VATRate) ? rowData.VATRate / 100 : rowData.VATRate;
        if (columnName === "VATGroupID" && dataCboVATGroup) { // Thuế suất
            if (rowData.VATGroupID) {
                const vATGroupObj = dataCboVATGroup.find(item => item.VATGroupID === rowData.VATGroupID);
                vatRateResult = Number.isInteger(vATGroupObj.VATRate) ? vATGroupObj.VATRate / 100 : vATGroupObj.VATRate;
                if (vatRateResult > 100) {
                    Config.popup.show("INFO", `${Config.lang("Thue_suat_khong_duoc_lon_hon")} 100%`);
                    rowData.VATRate = "";
                    return false;
                } else {
                    rowData.VATRate = vatRateResult * 100;
                }
            } else { // = Null (Btn Clear Value)
                vatRateResult = rowData.VATRate = null;
            }
        }
        const ACol01Rate = Number.isInteger(rowData.ACol01Rate) ? rowData.ACol01Rate / 100 : rowData.ACol01Rate; // Dạng %
        const ACol02Rate = Number.isInteger(rowData.ACol02Rate) ? rowData.ACol02Rate / 100 : rowData.ACol02Rate; // Dạng %
        const ACol03Rate = Number.isInteger(rowData.ACol03Rate) ? rowData.ACol03Rate / 100 : rowData.ACol03Rate; // Dạng %
        const ACol04Rate = Number.isInteger(rowData.ACol04Rate) ? rowData.ACol04Rate / 100 : rowData.ACol04Rate; // Dạng %
        const ACol05Rate = Number.isInteger(rowData.ACol05Rate) ? rowData.ACol05Rate / 100 : rowData.ACol05Rate; // Dạng %
        const exchangeR = parseInt(this.state.exchangeRate) || 1;
        const oAmountTmpResult = rowData.Quantity * rowData.OUnitPrice;
        const cAmountTmpResult = oAmountTmpResult * exchangeR;
        const adjustedUnitPriceResult = rowData.OUnitPrice
            + (rowData.ACol01 * rowData.ACol01Formula) + (ACol01Rate * rowData.OUnitPrice * rowData.ACol01Formula)
            + (rowData.ACol02 * rowData.ACol02Formula) + (ACol02Rate * rowData.OUnitPrice * rowData.ACol02Formula)
            + (rowData.ACol03 * rowData.ACol03Formula) + (ACol03Rate * rowData.OUnitPrice * rowData.ACol03Formula)
            + (rowData.ACol04 * rowData.ACol04Formula) + (ACol04Rate * rowData.OUnitPrice * rowData.ACol04Formula)
            + (rowData.ACol05 * rowData.ACol05Formula) + (ACol05Rate * rowData.OUnitPrice * rowData.ACol05Formula);
        const amountResult = rowData.Quantity * adjustedUnitPriceResult;
        const cAmountResult = amountResult * exchangeR;
        const unitPriceResult = vatRateResult >= 0 ? (adjustedUnitPriceResult * vatRateResult) + adjustedUnitPriceResult : adjustedUnitPriceResult; // Đơn giá sau thuế
        const totalAdjustedAmountResult = rowData.Quantity * unitPriceResult;
        const totalAdjustedCAmountResult = totalAdjustedAmountResult * exchangeR;
        rowData.UnitPrice = unitPriceResult;  // Đơn giá sau thuế
        rowData.OAmountTmp = !_.isNaN(oAmountTmpResult) ? oAmountTmpResult : null; // Thành tiền
        rowData.CAmountTmp = !_.isNaN(cAmountTmpResult) ? cAmountTmpResult : null; // Thành tiền QĐ
        rowData.AdjustedUnitPrice = !_.isNaN(adjustedUnitPriceResult) ? adjustedUnitPriceResult : null;  // Đơn giá sau DC
        rowData.Amount = !_.isNaN(amountResult) ? amountResult : null;  // Thành tiền sau DC
        rowData.CAmount = !_.isNaN(cAmountResult) ? cAmountResult : null;  // Thành tiền sau DC QĐ
        rowData.TotalAdjustedAmount = !_.isNaN(totalAdjustedAmountResult) ? totalAdjustedAmountResult : null; // Thành tiền sau thuế
        rowData.TotalAdjustedCAmount = !_.isNaN(totalAdjustedCAmountResult) ? totalAdjustedCAmountResult : null; // Thành tiền sau thuế QĐ
        if (e) {
            const { dataGrid } = this.state;
            rowIndex = e.component.getRowIndexByKey(e.key);
            if (rowIndex > -1 && !_.isEmpty(dataGrid)) dataGrid[rowIndex] = rowData;
        }
    }

    renderActionHover = (e) => {
        return (
            <div style={{ maxHeight: 20 }}>
                <GridActionBarHover>
                    {this.state.mode !== "view" &&
                        <GridActionBar
                            isPer={this.state.iPerD05F1611}
                            tooltipDelete={Config.lang("Xoa")}
                            onDeleteAction={() => {
                                this.deleteRow(e)
                            }} />}
                </GridActionBarHover>
            </div>
        );
    }

    formatDateTimeSave = (value) => {
        let result = null;
        if (Config.isValidDateTime(value)) {
            result = moment(value).format("YYYY-MM-DD");
        }
        return result;
    }

    saveData = async () => {
        await this.gridContainer.instance.saveEditData();
        const { statusVoucher, employeeID, dataGrid, validTo, validFrom, priceListID, contactPerson, telephone, linkDetail,
            currencyID, paymentTermID, paymentMethodID, objectID, voucherNum, exchangeRate, voucherTypeID, quotationID,
            objectTypeID, isQuotationNoneCode, divisionID, tranTypeID, voucherDate, deliveryMethodID, aStatus, linkMaster,
            shipAddressID, shipAddress, shipDateNum, mode
        } = this.state;
        const description = document.getElementById("descriptionW17F2000").value;
        const notesCustomer = document.getElementById("notesCustomerW17F2000").value;
        const objectAddress = document.getElementById("objectAddressW17F2000").value;
        const VoucherDate = this.formatDateTimeSave(voucherDate);
        const ValidFrom = this.formatDateTimeSave(validFrom);
        const ValidTo = this.formatDateTimeSave(validTo);
        let quantityOUnitPriceValidate = false;
        if (!_.isEmpty(dataGrid) && !quantityOUnitPriceValidate) dataGrid.forEach(item => {
            if (_.isNull(item.Quantity) || _.isNull(item.OUnitPrice)) {
                quantityOUnitPriceValidate = true;
                return;
            }
        });
        if (_.isEmpty(objectID)) {
            Config.popup.show("INFO", Config.lang("Khach_hang_khong_duoc_de_trong"));
            document.getElementById("customerW17F2000").focus();
            return false;
        } else if (_.isEmpty(divisionID)) {
            Config.popup.show("INFO", `${Config.lang("Don_vi_ban")} ${(Config.lang("Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("divisionW17F2000").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if (_.isEmpty(tranTypeID)) {
            Config.popup.show("INFO", `${Config.lang("Nghiep_vu")} ${(Config.lang("Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("tranTypeW17F2000").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        }
        else if (_.isEmpty(voucherNum)) {
            Config.popup.show("INFO", `${Config.lang("So_bao_gia")} ${(Config.lang("Khong_duoc_de_trong")).toLowerCase()}`);
            return false;
        } else if (_.isEmpty(voucherDate)) {
            Config.popup.show("INFO", `${Config.lang("Ngay_phieu")} ${(Config.lang("Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("voucherDateW17F2000").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if (_.isEmpty(employeeID)) {
            Config.popup.show("INFO", `${Config.lang("Nhan_vien_kinh_doanh")} ${(Config.lang("Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("employeeW17F2000").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if (_.isEmpty(currencyID)) {
            Config.popup.show("INFO", `${Config.lang("Loai_tien")} ${(Config.lang("Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("currencyW17F2000").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if (_.isEmpty(validFrom)) {
            Config.popup.show("INFO", `${Config.lang("Hieu_luc_tu")} ${(Config.lang("Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("validFromW17F2000").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if ((validFrom > validTo) && validTo) {
            Config.popup.show("INFO", Config.lang("Hieu_luc_den_phai_lon_hon_hieu_luc_tu"));
            this.setState({ validTo: null });
            document.getElementById("validToW17F2000").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if (!dataGrid || !(dataGrid.length > 0)) {
            Config.popup.show("INFO", Config.lang("Ban_chua_chon_hang_hoa"));
            return false;
        } else if (quantityOUnitPriceValidate) {
            Config.popup.show("INFO", Config.lang("Vui_long_nhap_day_du_don_gia_truoc_thue_va_so_luong_hang_hoa"));
            return false;
        }
        const param = {
            QuotationID: quotationID,
            ObjectTypeID: objectTypeID,
            ObjectAddress: objectAddress,
            VoucherTypeID: voucherTypeID,
            IsQuotationNoneCode: isQuotationNoneCode,
            ObjectID: objectID,
            DivisionID: divisionID,
            TranTypeID: tranTypeID,
            VoucherNum: voucherNum,
            VoucherDate,
            ValidTo,
            ValidFrom,
            EmployeeID: employeeID,
            ContactPerson: contactPerson,
            Telephone: telephone,
            PaymentTermID: paymentTermID,
            PaymentMethodID: paymentMethodID,
            DeliveryMethodID: deliveryMethodID,
            CurrencyID: currencyID,
            ExchangeRate: exchangeRate,
            PriceListID: priceListID,
            StatusVoucher: statusVoucher,
            NotesCustomer: notesCustomer,
            Description: description,
            AStatus: aStatus,
            LinkMaster: linkMaster,
            LinkDetail: linkDetail,
            ShipAddressID: shipAddressID,
            ShipAddress: shipAddress,
            ShipDateNum: _.toNumber(shipDateNum),
            detail: JSON.stringify(dataGrid)
        };
        this.setState({ loading: true });
        this.props.w17f2000Actions.saveData(param, async (error, data) => {
            this.setState({ loading: false });
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show("INFO", Config.lang("Loi"));
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                if (data && data.Status === 0 && data.QuotationID) {
                    const quotationID = (data.QuotationID) || this.state.quotationID;
                    if (mode === "add") {
                        const paramsCheckTask = {
                            QuotationID: quotationID,
                            TranTypeID: tranTypeID,
                            ObjectID: objectID,
                        };
                        this.props.w17f2000Actions.checkTask(paramsCheckTask, (er, cb) => {
                            if (cb.Status) {
                                const paramsGetForm = {
                                    KeyID: quotationID,
                                    TaskID: '',
                                    FFormID: 'W17F2000',
                                };
                                this.props.w18F1014Actions.getForm(paramsGetForm, (error, data) => {
                                    if (!Config.isEmpty(data)) {
                                        this.dataW18F1014.rowData = data;
                                        this.dataW18F1014.params = paramsGetForm;
                                        this.setState({ showW18F1014: true });
                                    }
                                });
                            }
                        });
                    }
                    const status = await this.saveAttach(quotationID); // Lưu đính kèm
                    if (status === 200) {
                        this.setState({
                            quotationID,
                            saveSuccess: true,
                            mode: "view", // Lưu thành công cho về "View"
                            // voucherNum: data.VoucherNum ? data.VoucherNum : this.state.voucherNum Để xem
                        }, () => {
                            this.isSaved = true;
                            this.loadForm(quotationID); // Load lại form
                        });
                        notify(Config.lang("Luu_thanh_cong"), "success", 2000);
                    }
                } else if (data && data.Status === 1 && _.isEmpty(data.QuotationID)) {
                    if (data.Message) Config.popup.show("INFO", Config.lang(data.Message)); // Xuất thông báo từ api
                    return false;
                }
            }
        });
    }

    saveAttach = async (keyID) => {
        const { dataAttach, mode } = this.state;
        const OldAttachData = this.OldAttachData;
        let status = 200;
        let attached = [...this.state.attached];
        if (dataAttach.length > 0) {
            for (let i = 0; i < dataAttach.length; i++) {
                const exp = dataAttach[i].file.name.split(".");
                const paramAttach = {
                    "KeyID": keyID,
                    "FileName": dataAttach[i].file.name,
                    "FileSize": dataAttach[i].file.size,
                    "FileExp": exp[exp.length - 1],
                    "TableName": "D05T0016"
                };
                const upload = await FileUploadAsync(dataAttach[i].file, paramAttach);
                if (upload && upload.code === 200) {
                    dataAttach[i].AttachmentID = upload.KeyID;
                    attached.push(dataAttach[i]);
                } else {
                    status = 400;
                    break;
                }
            }
            this.setState({
                dataAttach: [],
                attached: attached,
            });
        }
        if (mode === "edit") { // Xóa Attachment
            if (OldAttachData.length > 0) {
                let paramDeleteAttach;
                let paramRemoveAttach;
                for (let i = 0; i < OldAttachData.length; i++) {
                    paramDeleteAttach = {
                        "AttachmentID": OldAttachData[i].AttachmentID
                    };
                    paramRemoveAttach = {
                        "path": OldAttachData[i].URL
                    };
                    await Promise.all([
                        FileDeleteAsync(paramDeleteAttach),
                        FileRemoveAsync(paramRemoveAttach)
                    ]);
                }
                OldAttachData.length = 0;
            }
        }
        return status
    }

    loadForm = (newQuotationID = "") => {
        if (!this.getInfo()) return;
        let dynamicColummName = [];
        const { id, RequestID, IsCoppy } = this.getInfo();
        const { mode } = this.state;
        const { quotationID } = this.state;
        let idF = mode !== "add" ? id : quotationID;
        const paramStore = {
            Language: this.Language,
            DivisionID: Config.profile.DivisionID,
            QuotationID: !_.isEmpty(newQuotationID) ? newQuotationID : idF,
            RequestID: RequestID ? RequestID : "",
            IsCoppy: this.isSaved ? 3 : (IsCoppy || 0)
        };
        this.props.w17f2000Actions.loadForm(paramStore, async (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show("INFO", message);
                return false;
            } else if (!_.isEmpty(data)) {
                let selectedRow = [];
                if (data.ObjectID) this.getCboContactPerson({ ObjectID: data.ObjectID });
                if (data.NotesCustomer) document.getElementById("notesCustomerW17F2000").value = data.NotesCustomer;
                if (data.Description) document.getElementById("descriptionW17F2000").value = data.Description;
                if (data.ObjectAddress) document.getElementById("objectAddressW17F2000").value = data.ObjectAddress;
                if (data.detail && !_.isEmpty(data.detail.collumn)) {
                    dynamicColummName = data.detail.collumn.map(item => item.ColID);
                }
                if (data.detail && data.detail.rows) {
                    data.detail.rows.forEach(item => {
                        if (item.VATRate) {
                            item.VATRate = Number.isInteger(item.VATRate) ? item.VATRate : item.VATRate * 100; // Format lại dạng phần trăm
                        }
                    })
                    selectedRow = data.detail.rows.map(item => item.InventoryID);
                }
                this.isLoadForm = true;
                this.setState({
                    contactPerson: data.ContactPerson,
                    objectID: data.ObjectID,
                    currencyID: data.CurrencyID,
                    priceListID: data.PriceListID,
                    paymentTermID: data.PaymentTermID,
                    employeeID: data.EmployeeID,
                    tranTypeID: data.TranTypeID,
                    divisionID: data.DivisionID,
                    telephone: data.Telephone ? data.Telephone : null,
                    aStatus: data.AStatus,
                    quotationID: data.QuotationID,
                    objectTypeID: data.ObjectTypeID,
                    paymentMethodID: data.PaymentMethodID,
                    validTo: data.ValidTo,
                    validFrom: data.ValidFrom,
                    approveNote: data.ApproveNotesU,
                    approveUserName: data.ApproveUserName,
                    approveDate: data.ApproveDate ? moment(data.ApproveDate).format("DD/MM/YYYY HH:mm:ss") : "",
                    objectName: data.ObjectName,
                    objectAddress: data.ObjectAddress,
                    voucherNum: data.VoucherNum,
                    vatDisplay: data.VATDisplay,
                    exchangeRate: data.ExchangeRate,
                    statusVoucher: data.StatusVoucher,
                    voucherTypeID: data.VoucherTypeID,
                    deliveryMethodID: data.DeliveryMethodID,
                    isQuotationNoneCode: data.IsQuotationNoneCode,
                    voucherDate: data.VoucherDate,
                    shipAddressID: data.ShipAddressID,
                    shipAddress: data.ShipAddress,
                    shipDateNum: data.ShipDateNum,
                    dataGrid: data.detail.rows || [], //Data Lưới
                    dynamicColumm: data.detail.collumn,
                    dynamicColummName,
                    selectedRow,
                    attached: data.attached,  // Đính kèm
                    linkMaster: data.LinkMaster,
                    linkDetail: data.LinkDetail,
                    isClosed: data?.Closed || 0,
                    closingUserName: data.ClosingUserName,
                    closingDate: data.ClosingDate ? moment(data.ClosingDate).format("DD/MM/YYYY HH:mm:ss") : "",
                    closingReason: data.ClosingReason,
                });
            }
        })
    };

    renderCboUnit = (e) => {
        if (!e.row) return;
        const { row } = e;
        e.editorOptions.onFocusIn = args => {
            const input = args.element.querySelector(".dx-texteditor-input");
            input.select();
        };
        e.editorOptions.onKeyDown = args => {
            if (e.format && e.dataType === "number") {
                const value = args.event.target.value;
                const patternF = e.format ? e.format.split(".")[1].length : 0;
                if (!patternF && (args.event.key === "." || args.event.key === ",")) args.event.preventDefault();
                const decimalPart = value.includes(".") ? value.split(".")[1] : value.split(",") ? value.split(",")[1] : false;
                if (decimalPart && decimalPart.length === patternF && parseInt(args.event.key) && !window.getSelection().toString()) {
                    //Nếu phần thập phân leng = 3 và ký tụ tiếp theo là số, và không bôi đen input thì cho nhập
                    args.event.preventDefault();
                }
            }
        };
        e.editorOptions.onValueChanged = args => {
            row.data[e.dataField] = args.value;
            this.calculate(row.data, e.dataField);
            e.component.refresh();
        }
    }

    goBack = () => {
        const state = this.getInfo();
        const { formCall, formCallState, currentTabKey } = state;
        if (formCall) {
            browserHistory.push({
                pathname: Config.getRootPath() + formCall,
                state: { ...state, ...formCallState, currentTabKey }
            });
        } else {
            browserHistory.goBack();
        }
    };

    changeTelephone = (e) => {
        document.getElementById("telephoneW17F2000").value = Config.replaceSymbol(e.target.value);
        const value = Config.replaceSymbol(e.target.value);
        this.setState({ telephone: value });
    };

    deleteOldAttach = (attachment) => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            this.OldAttachData.push(attachment);
            const arr = this.state.attached.filter(function (obj) {
                return obj.AttachmentID !== attachment.AttachmentID;
            });
            this.setState({ attached: arr })
        });
    }

    deleteAttach = async (file) => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            const arr = this.state.dataAttach.filter((value) => {
                return value.FileName !== file.FileName;
            });
            this.setState({ dataAttach: arr });
        });
    }

    renderIcon = (file, url) => {
        const classes = useStyles();
        let ext = file ? file.split(".") : "";
        ext = ext[ext.length - 1];
        switch (ext) {
            case 'doc':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-doc.png")} />);
            case 'docx':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-docx.png")} />);

            case 'xls':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-xls.png")} />);
            case 'xlsx':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-xlsx.png")} />);

            case 'ppt':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-ppt.png")} />);
            case 'pptx':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-pptx.png")} />);

            case 'pdf':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-pdf.png")} />);

            case 'txt':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-txt.png")} />);

            default:
                return (<Image width={70} height={70} className={`${classes.imgAttachment} img-background`} alt="" src={url} />);
        }
    };

    checkFileType = (filetype) => {
        const allowedExtensions = [
            "image/jpeg",
            "image/png",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf"
        ];
        if (filetype.length > 0) {
            if (allowedExtensions.indexOf(filetype) === -1) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    };

    upLoadAttach = (e) => {
        if (!e) return;
        const files = _.get(e, "target.files", []);
        const sizeLimit = 20;
        if (files.length === 0) {
            return null;
        } else if (this.checkFileType(files[0].type) === false) {
            Config.popup.show("INFO", `${Config.lang("Dinh_dang_file_khong_hop_le")}`);
        } else if ((files[0].size / 1024 / 1024) > Number(sizeLimit)) {
            Config.popup.show("INFO", `${Config.lang("Dung_luong_File_khong_duoc_lon_hon")} ${sizeLimit} MB`);
        } else {
            let dataAttach = [...this.state.dataAttach];
            for (let file of files) {
                file.uploading = true;
                dataAttach.push({ file: file, FileName: file.name, URL: window.URL.createObjectURL(file) });
            }
            this.setState({ dataAttach });
        }
    };

    handleW17F2030Modal = (showW17F2030 = false, dataGrid = this.state.dataGrid, dataSelectedRow = []) => {
        let selectedRow = [];
        if (!_.isEmpty(dataGrid)) {
            selectedRow = dataGrid.filter(rs => rs.InventoryID !== '').map(item => item.InventoryID);
        } else {
            selectedRow = [];
        }
        // const { isTabRequest } = this.getInfo();
        // if (isTabRequest) {
        const dataFilter = this.state.dataGrid.filter(rs => rs.InventoryID === '');
        dataGrid = dataGrid.filter(rs => rs.InventoryID !== '');
        dataGrid = [...dataGrid, ...dataFilter];
        // }
        this.setState({
            showW17F2030,
            dataGrid,
            selectedRow: selectedRow
        });
    };

    renderNumberFormat = (e) => {
        if (!e) return false;
        let limitDecimal = 0;
        const { data } = e.row;
        const { column } = e;
        const { dataDMFSetting } = this.props;
        const { currencyObj, dynamicColumm } = this.state;
        const dataField = column.dataField;
        let decimalValue = data[dataField];
        if (_.isUndefined(decimalValue)) return;
        if (dataField.search("ACol") < 0) {
            switch (dataField) {
                case "UnitPrice":
                case "Amount":
                case "OUnitPrice":
                case "OAmountTmp":
                case "TotalAdjustedAmount":
                case "AdjustedUnitPrice":
                    limitDecimal = !_.isEmpty(currencyObj) ? currencyObj.OriginalDecimal : 0;
                    break;
                case "UnitPriceOnD14":
                case "CAmountTmp":
                case "CAmount":
                case "TotalAdjustedCAmount":
                    limitDecimal = !_.isEmpty(dataDMFSetting) ? dataDMFSetting.D90_ConvertedDecimals : 0;
                    break;
                case "Quantity":
                    limitDecimal = !_.isEmpty(dataDMFSetting) ? dataDMFSetting.D07_QuantityDecimals : 0;
                    break;
                default:
                    break;
            }
        } else { // Các cột Động Acol
            const currentACol = dynamicColumm && dynamicColumm.find(item => item.ColID === dataField);
            if (!_.isEmpty(currentACol)) limitDecimal = currentACol.DecimalPlace;
        }
        if (!_.isEmpty(decimalValue) && _.isString(decimalValue)) decimalValue = _.toNumber(decimalValue);
        return <NumberFormat decimalScale={limitDecimal} value={decimalValue} displayType={"text"} thousandSeparator={true} />
    };

    checkStatusCustomer = () => {
        const params = {
            Language: this.Language,
            DivisionID: this.state.divisionID,
            QuotationID: this.state.quotationID
        };
        this.props.w17f2000Actions.checkStatus(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (!_.isEmpty(data)) {
                if (data.Status === 0) {
                    this.setState({ showW17F2020: true });
                } else if (data.Status === 1 && data.Message) {
                    Config.popup.show("INFO", data.Message);
                }
            }
        })
    };

    onChangeVoucher = (TranTypeID, reloadEmptyGrid = false, obj) => {
        const { isTabRequest } = this.getInfo();
        let dataGrid = [...this.state.dataGrid];
        let selectedRow = this.state.selectedRow;
        let dynamicColumm = this.state.dynamicColumm;
        let dynamicColummName = this.state.dynamicColummName;
        const param = {
            DivisionID: this.state.divisionID,
            TranTypeID,
            QuotationID: (obj && obj.QuotationID ? obj.QuotationID : this.state.quotationID) || "",
            Language: this.Language
        };
        this.props.w17f2000Actions.loadVoucher(param, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data && !_.isEmpty(data)) {
                // Làm trống dữ liệu tất cả các cột trong lươi
                // if (reloadEmptyGrid && this.gridContainer) {
                //     let allColumnFieldName = [];
                //     const allColumn = this.gridContainer.instance.getVisibleColumns();
                //     allColumn && allColumn.forEach(item => {
                //         if (!_.isUndefined(item.dataField)) {
                //             allColumnFieldName.push(item.dataField);
                //         }
                //     });
                //     allColumnFieldName.forEach(key => {
                //         dataGrid.forEach(obj => {
                //             if (obj.hasOwnProperty(key)) {
                //                 if (_.isNumber(obj[key])) obj[key] = 0;
                //                 if (_.isString(obj[key])) obj[key] = "";
                //             }
                //         })
                //     });
                // }
                // Làm trống dữ liệu tất cả các cột trong lươi
                if (reloadEmptyGrid) {
                    dataGrid = []; // Xóa tất cả data
                    selectedRow = [];
                }
                if (this.state.mode !== "edit" && data.detail && !_.isEmpty(data.detail.column)) {
                    dynamicColumm = data.detail.column;
                    dynamicColummName = data.detail.column.map(item => item.ColID);
                }
                this.setState({
                    tranTypeID: TranTypeID,
                    vatDisplay: data.VATDisplay,
                    voucherNum: data.VoucherNum || "",
                    voucherTypeID: data.VoucherTypeID || "",
                    isQuotationNoneCode: data.IsQuotationNoneCode,
                    dataGrid,
                    selectedRow,
                    dynamicColumm,
                    dynamicColummName,
                }, () => {
                    if (isTabRequest) this.loadForm(); //Load lại form nếu gọi từ Tab Ghi Nhận Nhu Cầu}
                });
            }
        });
    };

    changeDate = (e, stateName) => {
        this.setState({ [stateName]: e.value ? moment(e.value).format() : null })
    };

    handleChangeTranType = (obj) => {
        const { divisionID, tranTypeID } = this.state;
        if (_.isEmpty(obj) || (obj && obj.TranTypeID === tranTypeID)) return;
        if (_.isEmpty(divisionID)) {
            Config.popup.show("INFO", "Ban_phai_chon_don_vi_ban");
        } else {
            if (this.state.dataGrid.length > 0 && tranTypeID !== obj.TranTypeID) {
                Config.popup.show("YES_NO", Config.lang("Thay_doi_nghiep_vu_se_xoa_du_lieu_luoi_mat_hang_ban_co_muon_tiep_tuc_khong"), () => {
                    this.onChangeVoucher(obj.TranTypeID, true, obj);
                })
            } else {
                this.onChangeVoucher(obj.TranTypeID, false, obj);
            }
        }
    };

    handleChangeDivision = (obj) => {
        const { DivisionID: divisionID = "" } = obj || {};
        let tranTypeID = this.state.tranTypeID;
        if (!_.isEmpty(this.state.dataCboTranType) && divisionID) {
            const currentDataTranType = this.state.dataCboTranType.find(item => item.DivisionID === divisionID);
            if (currentDataTranType && currentDataTranType.TranTypeID) tranTypeID = currentDataTranType.TranTypeID;
        }
        this.setState({
            divisionID,
            tranTypeID,
        });
    };

    handleChangeCurrency = async (obj, e) => {
        const grid = [...this.state.dataGrid];
        const { ID: currencyID = "", ExchangeRate: exchangeRate = "" } = obj || {};
        if (grid.length > 0) {
            Config.popup.show("YES_NO", Config.lang("Thay_doi_loai_tien_se_tinh_toan_lai_thanh_tien_QD_ban_co_muon_tiep_tuc_khong"), async () => {
                //Thực hiện tính toán các cột có liên quan đến exchangeRate
                const exchangeR = exchangeRate ? parseInt(exchangeRate) : 1;
                await grid.forEach((obj) => { // Tính toán lại lưới khi thay đổi
                    obj.CAmountTmp = obj.OAmountTmp * exchangeR; // Thành tiền QĐ
                    obj.CAmount = obj.Amount * exchangeR; // Thành tiền sau DC QĐ
                    obj.TotalAdjustedCAmount = obj.TotalAdjustedAmount * exchangeR; // Thành tiền sau thuế QĐ
                });
                this.setState({
                    currencyID,
                    exchangeRate,
                    dataGrid: grid,
                    currencyObj: e.selectedRowsData[0] || {}
                });
            })
        } else {
            this.setState({
                currencyID,
                exchangeRate,
                currencyObj: e.selectedRowsData[0] || {}
            });
        }
    };

    handleChangeContactPerson = (obj) => {
        const { ContactPerson: contactPerson = "" , Telephone: telephone = null } = obj || {};
        this.setState({
            contactPerson,
            telephone
        });
    };

    handleChangeDeliveryMethod = (obj) => {
        this.setState({ deliveryMethodID: obj?.DeliveryMethodID || "" });
    };

    handleChangePaymentTerm = (obj) => {
        this.setState({ paymentTermID: obj?.PaymentTermID || ""  });
    };

    handleChangeStatus = (obj) => {
        this.setState({ statusVoucher: obj?.StatusID || ""  });
    };

    handleChangeEmployee = (obj) => {
        this.setState({ employeeID: obj?.EmployeeID || ""  });
    };

    handleChangePriceList = (obj) => {
        this.setState({ priceListID: obj?.PriceListID || "" });
    };

    handleChangePaymentMethod = (obj) => {
        this.setState({ paymentMethodID: obj?.PaymentMethodID || "" });
    };

    handleChangeShipAddressID = (obj) => {
        const { ShipAddressID: shipAddressID = "", ShipAddress: shipAddress = "" } = obj || {};
        this.setState({ shipAddressID, shipAddress }, () => {
            if (this.refs["txtShipAddress"]) this.refs["txtShipAddress"].value = shipAddress;
        });
    };
    handleChangeShipAddress = (e) => {
        if (_.isEmpty(e)) return;
        const value = e?.target?.value || "";
        if (this.timerShipAddress) clearTimeout(this.timerShipAddress);
        this.timerShipAddress = setTimeout(() => {
            this.setState({shipAddress: value});
        }, 500);
    };

    handleChangeShipDateNum = (obj) => {
        if (this.timerShipDate) clearTimeout(this.timerShipDate);
        this.timerShipDate = setTimeout(() => {
            this.setState({ shipDateNum: obj?.value ?? null});
        }, 500);
    };

    renderColumn = (dynamicColumm) => {
        const classes = useStyles();
        let column = [];
        let totalItem = [];
        let columnDisplay = [];
        let visibleDynamicColummName = [];
        let columnDepentStatus = false;
        if (!_.isEmpty(dynamicColumm)) visibleDynamicColummName = (dynamicColumm.filter(item => item.Display === 1)).map(item => item.ColID);
        const totalItem1 = ["VATRate"]; // Các column Dạng thường
        const totalItem2 = ["Amount", "UnitPriceOnD14", "CAmount", "TotalAdjustedCAmount", "Quantity",
            "CAmountTmp", "OAmountTmp", "UnitPrice", "TotalAdjustedAmount", "OUnitPrice", "AdjustedUnitPrice"].concat(visibleDynamicColummName); // Các cột dạng thập phân
        [...totalItem1, ...totalItem2].forEach((value, i) => {
            if (value.includes(totalItem1)) {
                totalItem.push(
                    <TotalItem
                        key={`${i}Sum`}
                        displayFormat={"{0}"}
                        column={value}
                        summaryType={"sum"}
                    />
                )
            } else {
                totalItem.push(
                    <TotalItem
                        key={`${i}Sum`}
                        displayFormat={"{0}"}
                        column={value}
                        valueFormat={"#,##0.##"}
                        summaryType={"sum"}
                    />
                )
            }
        });

        dynamicColumm && dynamicColumm.forEach((value, index) => {
            if (!_.isEmpty(value)) {
                const visible = value.Display === 1; // = 1 hiển thị
                columnDisplay.push(value.Display);
                column.push(<Column key={index}
                    dataField={value.ColID}
                    visible={visible}
                    // allowEditing={!isQD}
                    caption={value.ColName}
                    cellRender={this.renderNumberFormat}
                    editCellRender={(e) => {
                        return (
                            <NumberFormat
                                style={{ width: '100%' }}
                                className={classes.numberFormatInput}
                                decimalScale={value.DecimalPlace || 0}
                                thousandSeparator={true}
                                isNumericString={true}
                                value={e.value}
                                onValueChange={(c) => e.setValue(_.toNumber(c.value))}
                            />
                        )
                    }}
                />)
            }
        });
        if (columnDisplay.length > 0) {
            const findShowDisplay = columnDisplay.includes(1);
            if (findShowDisplay) columnDepentStatus = true;
        }
        return {
            column,
            columnDepentStatus,
            totalItem: <Summary>{totalItem}</Summary>
            //Fix totalItem Summary khi nhận totalItem đầu tiên sẽ giữ nguyên giá trị dù sau khi caption api chạy  và update lại totalItem mới
        }
    };

    approveAction = () => {
        const { divisionID, quotationID } = this.state;
        const params = {
            DivisionID: divisionID,
            QuotationID: quotationID,
            Language: this.Language,
        };
        this.props.w17f2000Actions.approval(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                const message = data.Message ? data.Message : Config.lang("Duyet_thanh_cong");
                notify(message, "success", 400);
                this.loadForm(quotationID); // Load lại form
            }
        });
    };

    unApproveAction = () => {
        const { divisionID, quotationID } = this.state;
        const params = {
            DivisionID: divisionID,
            QuotationID: quotationID,
            Language: this.Language,
        };
        this.props.w17f2000Actions.cancelApproval(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                const message = data.Message ? data.Message : Config.lang("Bo_duyet_thanh_cong");
                notify(message, "success", 400);
                this.loadForm(quotationID); // Load lại form
            }
        });
    };

    actionClose = (type = "load", data) => {
        //type: "load" or "save"
        const { divisionID, quotationID, objectID, tranTypeID, paymentTermID, paymentMethodID } = this.state;
        const { RequestID, IsCoppy } = this.getInfo();
        let params = {
            DivisionID: divisionID,
            QuotationID: quotationID,
            RequestID: RequestID,
            TranTypeID: tranTypeID,
            ObjectID: objectID,
            DataType: type === "load" ? "LoadPopupClosed" : "Closed",
            IsCoppy: this.isSaved ? 3 : (IsCoppy || 0),
            PaymentTermID: paymentTermID,
            PaymentMethodID: paymentMethodID,
            Language: this.Language,
        };
        if (type === "save") params = {...params, ClosingReason: data?.ClosingReason};
        this.props.w17f2000Actions.actionClose(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                if (type === "load") {
                    this.setState({popupClose: {open: true, data}});
                } else {
                    if (data?.Status === 0) {
                        this.setState({popupClose: {open: false}});
                        this.loadForm(this.state.quotationID);
                        const message = data.Message ? data.Message : Config.lang("Dong_thanh_cong");
                        notify(message, "success", 400);
                    } else {
                        const message = data.Message ? data.Message : Config.lang("Dong_khong_thanh_cong");
                        notify(message, "error", 400);
                    }
                }
            }
        });
    };

    loadUnitPrice = () => {
        const { quotationID: QuotationID = "", tranTypeID: TranTypeID = "", paymentTermID: PaymentTermID = "", paymentMethodID: PaymentMethodID = "" } = this.state;
        const params = {
            FormID,
            QuotationID,
            TranTypeID,
            PaymentTermID,
            PaymentMethodID,
            LinkMaster: "",
        };
        this.props.w17f2000Actions.loadUnitPrice(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                this.defaultDynamicCol = data;
                if (this.gridContainer) this.gridContainer.instance.addRow();
            }
        })
    };

    checkEdit = () => {
        const params = {
            DivisionID: this.state.divisionID,
            QuotationID: this.state.quotationID,
            Language: this.Language,
        };
        this.props.w17f2000Actions.checkEdit(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (!_.isEmpty(data)) {
                if (data.Status === 0) {
                    this.setState({ mode: "edit", saveSuccess: false });
                } else if (data.Status === 1 && data.Message) {
                    Config.popup.show("INFO", data.Message);
                }
            }
        });
    };

    onReset = async () => {
        const { dataCboCurrency, dataCboDivision, dataCboEmployee } = this.props;
        const iPerD05F1611 = this.state.iPerD05F1611; // Giữ lại permision permis
        const iPerD05F5620 = this.state.iPerD05F5620; // Giữ lại permision nút Thêm Hình Ảnh
        const iPerD05F5625 = this.state.iPerD05F5625; // Giữ lại permision nút Đóng
        await this.setState(this.initialState); // Reset lại state ban đầu và cho mode là "add"
        document.getElementById("notesCustomerW17F2000").value = '';
        document.getElementById("descriptionW17F2000").value = '';
        this.setState({
            selectedRow: [],
            mode: "add",
            iPerD05F1611,
            iPerD05F5620,
            iPerD05F5625,
            currencyID: !_.isEmpty(dataCboCurrency) ? dataCboCurrency[0].ID : "", // Set lại Default giá trị mặt định đầu tiên  Loai_tien
            exchangeRate: !_.isEmpty(dataCboCurrency) ? dataCboCurrency[0].ExchangeRate : "", // Set lại Default giá trị mặt định đầu tiên
            divisionID: !_.isEmpty(dataCboDivision) ? dataCboDivision[0].DivisionID : "", // Set lại Default giá trị mặt định đầu tiên Don_vi_ban
            employeeID: this.getDefaultEmployeeID(!_.isEmpty(dataCboEmployee) ? dataCboEmployee[0].EmployeeID : ""), // Set lại Default giá trị mặt định đầu tiên  Nhan_vien_kinh_doanh
        });
    };

    onAddNewRow = () => {
        this.loadUnitPrice();
    };

    showW17F2030 = () => {
        const { priceListID } = this.state;
        if (Number(this.usePriceList) === 1 && !priceListID) {
            Config.popup.show("INFO", Config.lang("Bang_gia_khong_duoc_de_trong"));
        } else {
            this.setState({ showW17F2030: true });
        }
    };

    render() {
        if (!this.getInfo(true)) return null;
        const classes = useStyles();
        const { isTabRequest, formCall } = this.getInfo();
        // const quotationID = this.state.mode !== "add" ? id : this.state.quotationID;
        const { dataCboStatus, dataCboDivision, dataDMFSetting, dataCboPaymentMethod, dataCboVATGroup,
            dataCboTranType, dataCboEmployee, dataCboDeliveryMethod, dataCboCurrency, dataCboPaymentTerm,
            dataShipAddress, dataCboPacking
        } = this.props;
        const { dataAttach, attached, exchangeRate, divisionID, showW17F2020, dataCboContactPerson, mode,
            dataGrid, approveNote, aStatus, saveSuccess, isQuotationNoneCode, showW18F1015, collapse, validFrom,
            objectName, telephone, voucherNum, priceListData, dynamicColumm, iPerD05F1611, iPerD05F1632, currencyID, selectedRow,
            priceListID, validTo, showW17F2030, vatDisplay, tranTypeID, voucherDate, objectID, showW17F2035,
            paymentTermID, employeeID, contactPerson, paymentMethodID, deliveryMethodID, currencyObj, statusVoucher, shipAddressID,
            shipAddress, shipDateNum, iPerD05F5620, showW18F1014, approveUserName, approveDate, popupClose, isClosed: _isClosed,
            closingUserName, closingDate, closingReason, iPerD05F5625
        } = this.state;
        const isClosed = Number(_isClosed) === 1;
        const colSize = aStatus === 1 ? 4 : 6;
        const { column, totalItem, columnDepentStatus } = this.renderColumn(dynamicColumm);
        const btnAddRowStattus = isQuotationNoneCode === 1 && (mode === "add" || mode === "edit") && mode !== "view";
        // const btnAddChosenRowStatus = !_.isEmpty(tranTypeID) && isQuotationNoneCode === 0 && (mode !== "add" || mode !== "edit") && mode !== "view";
        const numberFormatEditName = ["OUnitPrice", "Quantity", "ACol01", "ACol02", "ACol03", "ACol04", "ACol05", "ACol01Formula", "ACol02Formula", "ACol03Formula", "ACol04Formula", "ACol05Formula",
            "ACol01Rate", "ACol02Rate", "ACol03Rate", "ACol04Rate", "ACol05Rate"]; // Các cột có dùng React Number Format

        const reportTypeID = "W17F2000";
        const paramPrint = [
            {type: "Varchar", id: "UserID", value: Config.profile.UserID},
            {type: "Varchar", id: "Language", value: Config.language || '84'},
            {type: "VarChar", id: "DivisionID", value: Config.profile.DivisionID || "" },
            {type: "VarChar", id: "DataType", value: "Export_Quotation"},
            {type: "VarChar", id: "QuotationID", value: this.state.quotationID},
        ];
        const btnDelClass = !!this.state.isDisabled ? 'hide' : '';
        const colSpace = aStatus === 1 ? 'none' : 'block';

        return (
            <div id={"W17F2000"} className={"page-container"}>
                {this.state.loading && <Loading />}
                {popupClose.open && <PopupClose
                    data={popupClose.data}
                    onHide={() => this.setState({popupClose: {open: false}})}
                    onSave={data => {
                        this.actionClose("save", data);
                    }}
                />}
                {showW18F1014 &&
                    <W18F1014
                        loadData={(cb) => cb(this.dataW18F1014)}
                        data={this.dataW18F1014}
                        formID={'W17F2000'}
                        state={this.getInfo()}
                        onHide={() => this.setState({ showW18F1014: false })}

                    />}
                {showW17F2030 && <W17F2030PopUp
                    formID={"W17F2000"}
                    maxWidth={"lg"}
                    objectID={objectID}
                    divisionID={divisionID}
                    priceListID={priceListID}
                    currencyID={currencyID}
                    tranTypeID={tranTypeID}
                    paymentMethodID={paymentMethodID}
                    paymentTermID={paymentTermID}
                    openModal={showW17F2030}
                    selectedRow={selectedRow}
                    dataGridMaster={dataGrid}
                    getAll
                    onClose={(data, selectedRow) => this.handleW17F2030Modal(false, data, selectedRow)}
                />}
                <div style={{ marginLeft: "10px" }} className={"grid-title"}>{Config.lang("Lap_bao_gia")}</div>
                <EventTracking action={"FORM"} label={"W17F2000"} />
                {showW18F1015 && <W18F1015CustomerPopUp
                    mode={1}
                    companyID={""}
                    formID={"W17F2000"}
                    typeID={"AddQuotation"}
                    onHide={(data) => this.hideW18F1015(data)}
                />}
                {showW17F2020 && <W17F2020PopUp
                    companyID={""}
                    statusID={statusVoucher}
                    voucherID={this.state.quotationID}
                    formID={"W17F2000"}
                    type={"UpStatusCustomer"}
                    onHide={(reLoadStatus) => {
                        this.hideW17F2020();
                        if (reLoadStatus) this.loadForm(this.state.quotationID);
                    }}
                />}
                {showW17F2035 && <W17F2035PopUp
                    voucherID={this.state.quotationID}
                    type={"AddImages"}
                    formID={"W17F2000"}
                    onClose={(reLoadStatus) => {
                        this.setState({ showW17F2035: false });
                        if (reLoadStatus) this.loadForm(this.state.quotationID);
                    }}
                />}
                <Row className={"mgb4x"}>
                    <Col xs={12}>
                        <div className={"wrap align-center"}>
                            <Button
                                size={"small"}
                                color={'warning'}
                                startIcon={"Back"}
                                label={Config.lang('Quay_lai')}
                                labelProps={{ style: { color: 'initial' } }}
                                onClick={this.goBack}
                            />
                            <Button
                                size={"small"}
                                color={'info'}
                                startIcon={"Add"}
                                className={"mgl1x"}
                                label={Config.lang('Nhap_tiep')}
                                labelProps={{ style: { color: 'initial' } }}
                                disabled={iPerD05F1611 < 2 || isTabRequest || mode === "add" || formCall === "W17F1026"}
                                onClick={() => this.onReset()}
                            />
                            <Button
                                size={"small"}
                                color={'info'}
                                startIcon={"Save"}
                                className={"mgl1x"}
                                label={Config.lang('Luu')}
                                labelProps={{ style: { color: 'initial' } }}
                                disabled={mode === "view" || saveSuccess || iPerD05F1611 < 2 || isClosed}
                                onClick={() => this.saveData()}
                            />
                            <Button
                                size={"small"}
                                color={'info'}
                                startIcon={"Edit"}
                                className={"mgl1x"}
                                label={Config.lang('Sua')}
                                labelProps={{ style: { color: 'initial' } }}
                                disabled={iPerD05F1611 < 3 || (mode === "edit" && !saveSuccess) || mode === "add" || isClosed}
                                onClick={() => this.checkEdit()}
                            />
                            <Button
                                size={"small"}
                                color={'success'}
                                startIcon={"Approval"}
                                className={"mgl1x"}
                                label={Config.lang('Duyet')}
                                labelProps={{ style: { color: 'initial' } }}
                                disabled={mode !== "view" || iPerD05F1632 < 2 || isClosed}
                                onClick={() => this.approveAction()}
                            />
                            <Button
                                size={"small"}
                                color={'danger'}
                                startIcon={"CancelFilled"}
                                className={"mgl1x"}
                                label={Config.lang('Bo_duyet')}
                                labelProps={{ style: { color: 'initial' } }}
                                disabled={mode !== "view" || iPerD05F1632 < 4 || isClosed}
                                onClick={() => this.unApproveAction()}
                            />
                            <Button
                                size={"small"}
                                color={'default'}
                                startIcon={"SyncHorizontal"}
                                className={"mgl1x"}
                                label={Config.lang('Cap_nhat_trang_thai_thuc_hien_DH')}
                                labelProps={{ style: { color: 'initial' } }}
                                disabled={mode !== "view" || iPerD05F1611 < 4}
                                onClick={() => {
                                    this.checkStatusCustomer();
                                }}
                            />
                            <Button
                                size={"small"}
                                color={'success'}
                                startIcon={"Upload"}
                                className={"mgl1x"}
                                label={Config.lang('Dinh_kem')}
                                labelProps={{ style: { color: 'initial' } }}
                                disabled={mode !== "add" && mode !== "edit"}
                                onClick={() => {
                                    document.getElementById("attachmentW17F2000").click();
                                }}
                            />
                            {iPerD05F5620 >= 2 &&
                                <Button
                                    size={"small"}
                                    color={'info'}
                                    startIcon={"Attachment"}
                                    className={"mgl1x"}
                                    label={Config.lang('Them_hinh_anh_mat_hang')}
                                    labelProps={{ style: { color: 'initial' } }}
                                    disabled={mode !== "view" || isClosed}
                                    onClick={() => {
                                        this.setState({ showW17F2035: true });
                                    }}
                                />
                            }
                            <ButtonExport name={Config.lang('CRM_In1')}
                                disabled={mode === "add" || iPerD05F1611 < 2}
                                transTypeID={'Export_Quotation'}
                                reportTypeID={reportTypeID}
                                param={paramPrint}
                                onlyShowPopup={true}
                                className={'mgl1x'} />
                            {mode === "view" && <Button
                                size={"small"}
                                color={'danger'}
                                startIcon={"CancelFilled"}
                                className={"mgl1x"}
                                label={Config.lang('Dong1')}
                                labelProps={{ style: { color: 'initial' } }}
                                disabled={isClosed || iPerD05F5625 < 2}
                                onClick={() => this.actionClose()}
                            />}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <Collapse open={collapse}>
                            <Row id={"masterW17F2000"} className={"mgb10x"} style={{ transition: "0.2s" }}>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label required={mode !== "view"}>{Config.lang("Khach_hang")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <div style={{ position: "relative" }}>
                                                <input className={`form-control ${classes.inputPopup}`}
                                                    readOnly={true}
                                                    type={"text"}
                                                    id={"customerW17F2000"}
                                                    value={objectName}
                                                    disabled={mode === "view" || mode === "edit" || saveSuccess}
                                                    onClick={() => { this.setState({ showW18F1015: true }) }}
                                                />
                                                <i className={`fa fa-sort-down ${classes.dropdownIconCb}`} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label required={mode !== "view"}>{Config.lang("Don_vi_ban")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <Combo
                                                className={classes.textCombo}
                                                id={"divisionW17F2000"}
                                                wordWrapEnabled={true}
                                                showHeader={false}
                                                // required={true} // Tạm ẩn
                                                virtual={true}
                                                disabled={mode === "view" || mode === "edit" || saveSuccess}
                                                dataSource={dataCboDivision}
                                                value={divisionID}
                                                placeholder={""}
                                                valueExpr={"DivisionID"}
                                                displayExpr={"DivisionID"}
                                                onActionWhenSelectChange={this.handleChangeDivision}
                                            >
                                                <Column
                                                    dataField={"DivisionCustomName"}
                                                    width={"auto"}
                                                    dataType={"string"} />
                                            </Combo>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label required={mode !== "view"}>{Config.lang("Nghiep_vu")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <Combo
                                                className={classes.textCombo}
                                                id={"tranTypeW17F2000"}
                                                wordWrapEnabled={true}
                                                showHeader={false}
                                                // virtual={true}
                                                placeholder={""}
                                                // required={true} // Tạm ẩn
                                                // valueExpr={"TranTypeID"}
                                                displayExpr={"TranTypeID"}
                                                disabled={mode === "view" || mode === "edit" || saveSuccess}
                                                dataSource={dataCboTranType}
                                                value={tranTypeID}
                                                onActionWhenSelectChange={this.handleChangeTranType}
                                            >
                                                <Column
                                                    dataField={"TranTypeCustomName"}
                                                    width={"auto"}
                                                    dataType={"string"} />
                                            </Combo>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label required={mode !== "view"}>{Config.lang("So_bao_gia")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <div className={"display_row align-center"}>
                                                <input
                                                    value={voucherNum}
                                                    className={"form-control"}
                                                    readOnly={true} />
                                                <ButtonIcon
                                                    viewType={"text"}
                                                    name={"SyncHorizontal"}
                                                    disabled={mode !== "add"}
                                                    className={"mgl4x"}
                                                    onClick={() => { this.onChangeVoucher(tranTypeID, false) }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={12}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("Dia_chi_khach_hang")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <textarea
                                                style={{ backgroundColor: 'transparent !important' }}
                                                className={`form-control`}
                                                rows={1}
                                                id={"objectAddressW17F2000"}
                                                disabled={mode === "view"}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label required={mode !== "view"}>{Config.lang("Ngay_phieu")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <DateBox
                                                type={"date"}
                                                width={"100%"}
                                                pickerType={"calendar"}
                                                id={"voucherDateW17F2000"}
                                                displayFormat={"dd/MM/y"}
                                                dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                                placeholder={Config.lang("Ngay_phieu")}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showAnalogClock={false}
                                                value={voucherDate}
                                                disabled={mode === "view" || saveSuccess}
                                                ref={(ref) => this.fromDate = ref}
                                                onValueChanged={(e) => this.changeDate(e, "voucherDate")}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label required={mode !== "view"}>{Config.lang("Hieu_luc_tu")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <DateBox
                                                className={classes.textCombo}
                                                width={"100%"}
                                                type={"date"}
                                                pickerType={"calendar"}
                                                id={"validFromW17F2000"}
                                                displayFormat={"dd/MM/y"}
                                                placeholder={Config.lang("Hieu_luc_tu")}
                                                dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showAnalogClock={false}
                                                value={validFrom}
                                                ref={(ref) => this.fromDate = ref}
                                                disabled={mode === "view" || saveSuccess}
                                                onValueChanged={(e) => this.changeDate(e, "validFrom")}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("Hieu_luc_den")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <DateBox
                                                className={classes.textCombo}
                                                type={"date"}
                                                width={"100%"}
                                                id={"validToW17F2000"}
                                                pickerType={"calendar"}
                                                displayFormat={"dd/MM/y"}
                                                dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                                placeholder={Config.lang("Hieu_luc_den")}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                showAnalogClock={false}
                                                value={validTo}
                                                disabled={mode === "view" || saveSuccess}
                                                onValueChanged={(e) => this.changeDate(e, "validTo")}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label required={mode !== "view"}>{Config.lang("Nhan_vien_kinh_doanh")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <Combo
                                                className={classes.textCombo}
                                                id={"employeeW17F2000"}
                                                wordWrapEnabled={true}
                                                showHeader={false}
                                                virtual={true}
                                                // required={true} // Tạm ẩn
                                                disabled={mode === "view" || saveSuccess}
                                                dataSource={dataCboEmployee}
                                                value={employeeID}
                                                placeholder={""}
                                                valueExpr={"EmployeeID"}
                                                displayExpr={"EmployeeName"}
                                                onActionWhenSelectChange={this.handleChangeEmployee}
                                            >
                                                <Column
                                                    dataField={"EmployeeCustomName"}
                                                    width={"auto"}
                                                    dataType={"string"} />
                                            </Combo>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("Nguoi_lien_he")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <Combo
                                                className={classes.textCombo}
                                                virtual={true}
                                                showHeader={false}
                                                showClearButton={true}
                                                // required={true} // Tạm ẩn
                                                placeholder={""}
                                                valueExpr={"ContactPerson"}
                                                displayExpr={"ContactPerson"}
                                                disabled={mode === "view" || saveSuccess}
                                                dataSource={dataCboContactPerson}
                                                value={contactPerson}
                                                onActionWhenSelectChange={this.handleChangeContactPerson}
                                            >
                                                <Column
                                                    dataField={"ContactPerson"}
                                                    width={"auto"}
                                                    dataType={"string"}
                                                    cellRender={(e) => `${e.row.data.ContactPerson}`} />
                                            </Combo>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("Dien_thoai")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <Input id={"telephoneW17F2000"}
                                                className={classes.inputText}
                                                disabled={mode === "view" || saveSuccess}
                                                placeholder={Config.lang("Dien_thoai")}
                                                type={"number"}
                                                autoComplete={"off"}
                                                value={telephone || ""}
                                                onChange={(e) => { this.changeTelephone(e) }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("Dieu_khoan_TM")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <Combo
                                                className={classes.textCombo}
                                                showClearButton={true}
                                                wordWrapEnabled={true}
                                                showHeader={false}
                                                virtual={true}
                                                disabled={mode === "view" || (mode === "add" && saveSuccess)}
                                                dataSource={dataCboPaymentTerm}
                                                value={paymentTermID}
                                                placeholder={""}
                                                valueExpr={"PaymentTermID"}
                                                displayExpr={"PaymentTermName"}
                                                onActionWhenSelectChange={this.handleChangePaymentTerm}
                                            >
                                                <Column dataField={"PaymentTermCustomName"}
                                                    width={"auto"}
                                                    dataType={"string"} />
                                            </Combo>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={0} sm={6} md={8} lg={3} sx={{ display: { xs: 'none', sm: 'block' } }}></Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("PT_thanh_toan")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <Combo
                                                className={classes.textCombo}
                                                showClearButton={true}
                                                showHeader={false}
                                                // required={true} // Tạm ẩn
                                                virtual={true}
                                                placeholder={""}
                                                valueExpr={"PaymentMethodID"}
                                                displayExpr={"PaymentMethodName"}
                                                disabled={mode === "view" || saveSuccess}
                                                dataSource={dataCboPaymentMethod}
                                                value={paymentMethodID}
                                                onActionWhenSelectChange={this.handleChangePaymentMethod}
                                            >
                                                <Column
                                                    dataField={"PaymentMethodCustomName"}
                                                    width={"auto"}
                                                    dataType={"string"} />
                                            </Combo>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("PT_giao_hang")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <Combo
                                                className={classes.textCombo}
                                                showClearButton={true}
                                                wordWrapEnabled={true}
                                                showHeader={false}
                                                virtual={true}
                                                placeholder={""}
                                                valueExpr={"DeliveryMethodID"}
                                                displayExpr={"DeliveryMethodName"}
                                                disabled={mode === "view" || saveSuccess}
                                                dataSource={dataCboDeliveryMethod}
                                                value={deliveryMethodID}
                                                onActionWhenSelectChange={this.handleChangeDeliveryMethod}
                                            >
                                                <Column
                                                    dataField={"DeliveryMethodCustomName"}
                                                    width={"auto"}
                                                    dataType={"string"} />
                                            </Combo>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label required={mode !== "view"}>{Config.lang("Loai_tien")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <div className={"display_row align-center"}>
                                                <div className={"mgr4x"} style={{ flexBasis: '60%' }}>
                                                    <Combo
                                                        className={classes.textCombo}
                                                        // required={true} // Tạm ẩn
                                                        totalItems={62}
                                                        virtual={true}
                                                        placeholder={""}
                                                        valueExpr={"ID"}
                                                        displayExpr={"ID"}
                                                        id={"currencyW17F2000"}
                                                        dataSource={dataCboCurrency}
                                                        value={currencyID}
                                                        disabled={mode === "view" || saveSuccess}
                                                        onActionWhenSelectChange={(key, e) => {
                                                            this.handleChangeCurrency(key, e)
                                                        }}
                                                    >
                                                        <Column dataField={"ID"}
                                                            caption={""}
                                                            dataType={"string"} />
                                                    </Combo>
                                                </div>
                                                <NumberFormat
                                                    decimalScale={(dataDMFSetting && dataDMFSetting.D90_ConvertedDecimals) || 0}
                                                    value={exchangeRate}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("Bang_gia")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <Combo
                                                className={classes.textCombo}
                                                disabled={mode === "view" || saveSuccess}
                                                virtual={true}
                                                showHeader={false}
                                                // required={true} // Tạm ẩn
                                                dataSource={priceListData}
                                                value={priceListID}
                                                placeholder={""}
                                                // valueExpr={"PriceListID"}
                                                id={"priceListW17f2000"}
                                                displayExpr={"DescriptionU"}
                                                onActionWhenSelectChange={this.handleChangePriceList}
                                            >
                                                <Column
                                                    dataField={"PriceListCustomName"}
                                                    width={"auto"}
                                                    dataType={"string"} />
                                            </Combo>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={0} sm={0} md={8} lg={0} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'none' } }}></Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("Trang_thai_KH")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <Combo
                                                className={classes.textCombo}
                                                showClearButton={true}
                                                showHeader={false}
                                                // required={true} // Tạm ẩn
                                                virtual={true}
                                                placeholder={""}
                                                valueExpr={"StatusID"}
                                                displayExpr={"StatusName"}
                                                value={statusVoucher}
                                                dataSource={dataCboStatus}
                                                disabled={mode === "view" || saveSuccess}
                                                onActionWhenSelectChange={this.handleChangeStatus}
                                            >
                                                <Column
                                                    dataField={"StatusCustomName"}
                                                    width={"auto"}
                                                    dataType={"string"} />
                                            </Combo>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={9}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("Ghi_chu_KH")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <textarea
                                                style={{ backgroundColor: 'transparent !important' }}
                                                className={`form-control`}
                                                rows={1}
                                                id={"notesCustomerW17F2000"}
                                                disabled={mode === "view" || saveSuccess}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("Noi_nhan_hang")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <Combo
                                                className={classes.textCombo}
                                                showClearButton={true}
                                                showHeader={false}
                                                // required={true} // Tạm ẩn
                                                virtual={true}
                                                placeholder={""}
                                                valueExpr={"ShipAddressID"}
                                                displayExpr={"ShipAddressID"}
                                                value={shipAddressID}
                                                dataSource={dataShipAddress}
                                                disabled={mode === "view" || saveSuccess}
                                                onActionWhenSelectChange={this.handleChangeShipAddressID}
                                            >
                                                <Column
                                                    dataField={"ShipAddressID"}
                                                    width={"auto"}
                                                    dataType={"string"}
                                                />
                                            </Combo>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={6} md={8} lg={6} className={"display_row align-right"}>
                                    <input className={'form-control'}
                                        ref={"txtShipAddress"}
                                        onChange={this.handleChangeShipAddress}
                                        disabled={mode === "view" || saveSuccess}
                                        defaultValue={shipAddress} />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("Thoi_gian_giao_hang")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <NumberFormat
                                                style={{ width: '100%', textAlign: "right" }}
                                                className={"form-control"}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                // isNumericString={true}
                                                disabled={mode === "view" || saveSuccess}
                                                value={shipDateNum}
                                                onValueChange={this.handleChangeShipDateNum}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={0} sm={aStatus === 1 ? 0 : 6} md={aStatus === 1 ? 0 : 8} lg={0} sx={{ display: { xs: 'none', sm: colSpace, md: colSpace, lg: 'none' } }}></Col>
                                <Col xs={12} sm={6} md={aStatus === 1 ? 8 : 6} lg={colSize}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("Ghi_chu")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <textarea
                                                style={{ backgroundColor: 'transparent !important' }}
                                                rows={4}
                                                className={"form-control"}
                                                id={"descriptionW17F2000"}
                                                disabled={mode === "view" || saveSuccess}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                {isClosed && <Col xs={12} sm={6} md={6} lg={4} className={"display_row align-center"}>
                                    <Image alt={""}
                                        id={"passStampW17F2000"}
                                        width={'100%'}
                                        height={'auto'}
                                        style={{ flexBasis: '50%', flexGrow: 1 }}
                                        src={require("./../../images/closed-stamp.jpg")} />
                                    <div style={{ flexGrow: 1 }}>
                                        <div className={"display_row align-center mgb1x"}><Typography className={"pdr1x"} type={"h4"}>{Config.lang("Nguoi_dong")}:</Typography><Typography color={"secondary"} type={"p2"}>{closingUserName}</Typography></div>
                                        <div className={"display_row align-center mgb1x"}><Typography className={"pdr1x"} type={"h4"}>{Config.lang("Ngay_dong")}:</Typography><Typography color={"secondary"} type={"p2"}>{closingDate}</Typography></div>
                                        <div className={"display_row align-center mgb1x"}><Typography className={"pdr1x"} type={"h4"}>{Config.lang("Ly_do_dong")}:</Typography><Typography color={"secondary"} type={"p2"}>{closingReason}</Typography></div>
                                        {/*<div id={"approveNoteW17F2000"}> {closingReason}  </div>*/}
                                    </div>
                                </Col>}
                                {aStatus === 1 && !isClosed &&
                                    <Col xs={12} sm={6} md={6} lg={4} className={"display_row align-center"}>
                                        <Image alt={""}
                                            id={"passStampW17F2000"}
                                            width={'100%'}
                                            height={'auto'}
                                            style={{ flexBasis: '50%', flexGrow: 1 }}
                                            src={require("./../../images/approve-stamp.png")} />
                                        <div style={{ flexGrow: 1 }}>
                                            <div className={"display_row align-center mgb1x"}><Typography className={"pdr1x"} type={"h4"}>{Config.lang("Nguoi_duyet")}:</Typography><Typography color={"secondary"} type={"p2"}>{approveUserName}</Typography></div>
                                            <div className={"display_row align-center"}><Typography className={"pdr1x"} type={"h4"}>{Config.lang("Ngay_duyet")}:</Typography><Typography color={"secondary"} type={"p2"}>{approveDate}</Typography></div>
                                            <div id={"approveNoteW17F2040"}> {approveNote} </div>
                                        </div>
                                    </Col>}
                                <Col xs={12} sm={6} md={6} lg={colSize}>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>{Config.lang("Tai_lieu_dinh_kem")}</Label>
                                        </Col>
                                        <Col xs={12}>
                                            <div className={"display_row list-attach"} style={{ minHeight: 94 }}>
                                                {(dataAttach.length === 0 && attached.length === 0) && <span
                                                    className={"text-no-data"}>{Config.lang("Khong_co_du_lieu")}</span>}
                                                {attached && attached.map((value, index) => {
                                                    return (
                                                        <div className={'view-attachment mgr1x'}
                                                            key={'view-attachment' + index}>
                                                            <a href={Config.env.api + '/file/get?AttachmentID=' + value.AttachmentID}
                                                                download>
                                                                {this.renderIcon(value.FileName, value.URL)}
                                                            </a>
                                                            <Typography type={"p3"} className={"mgt1x"}>{value.FileName}</Typography>
                                                            <ButtonIcon circular
                                                                viewType={'ghost'}
                                                                className={`deleteAttach ${btnDelClass}`}
                                                                size={'small'}
                                                                name={"Delete"}
                                                                color={"danger"}
                                                                onClick={() => {
                                                                    this.deleteOldAttach(value)
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                                {dataAttach.map((value, index) => {
                                                    if (value.uploading) {
                                                        return (<div className={"view-loading"}>
                                                            <i key={index}
                                                                className="fas fa-spinner fa-spin" />
                                                        </div>)
                                                    } else return (
                                                        <div className={'view-attachment mgr1x'}
                                                            key={'view-attachment1' + index}>
                                                            {this.renderIcon(value.FileName, value.URL)}
                                                            <Typography type={"p3"} className={"mgt1x"}>{value.FileName}</Typography>
                                                            <ButtonIcon circular
                                                                viewType={'ghost'}
                                                                className={`deleteAttach ${btnDelClass}`}
                                                                size={'small'}
                                                                name={"Delete"}
                                                                color={"danger"}
                                                                onClick={() => {
                                                                    this.deleteAttach(value)
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Collapse>
                    </Col>
                    <Col xs={12}>
                        <div className={"mgb1x"} style={{ marginLeft: 'auto', display: 'block' }}>
                            <Button
                                size={"small"}
                                color={'info'}
                                startIcon={"Add"}
                                label={Config.lang('Them')}
                                disabled={!btnAddRowStattus}
                                labelProps={{ style: { color: 'initial' } }}
                                onClick={() => this.onAddNewRow()}
                            />
                            <Button
                                size={"small"}
                                color={'primary'}
                                viewType={"outlined"}
                                className={`mgl1x`}
                                disabled={mode === "view"}
                                label={Config.lang('Chon_hang_hoa')}
                                labelProps={{ style: { color: 'initial' } }}
                                onClick={() => this.showW17F2030()}
                            />
                            <ButtonIcon
                                width={22}
                                height={22}
                                viewType={"ghost"}
                                color={"info"}
                                className={"pull-right"}
                                name={"ArrowDownFilled"}
                                style={{ transition: 'transform .5s', transform: `${collapse ? 'rotate(177deg)' : ''}` }}
                                onClick={() => this.setState({ collapse: !this.state.collapse })}
                            />
                        </div>
                        <GridContainer
                            reference={(e) => this.gridContainer = e}
                            totalItems={dataGrid.length}
                            dataSource={dataGrid}
                            hoverStateEnabled={true}
                            elementAttr={{ class: classes.customGridCss }}
                            onEditorPreparing={(e) => {
                                this.renderCboUnit(e);
                            }}
                            onInitNewRow={(eMethod) => {
                                eMethod.data.VATRate = null;
                                eMethod.data.UnitPrice = null;
                                eMethod.data.UnitPriceOnD14 = null;
                                eMethod.data.UnitID = "";
                                eMethod.data.VATGroupID = "";
                                eMethod.data.InventoryID = "";
                                eMethod.data.InventoryName = "";
                                eMethod.data.QuotationItemID = "";
                                eMethod.data.Notes = "";
                                if (!_.isEmpty(this.defaultDynamicCol) && _.isObject(this.defaultDynamicCol)) {
                                    const arrayColKey = Object.keys(this.defaultDynamicCol);
                                    for (let i = 0; i < arrayColKey.length; i++) {
                                        eMethod.data[arrayColKey[i]] = this.defaultDynamicCol[arrayColKey[i]];
                                    }
                                }
                            }}
                            onRowInserted={(e) => {
                                if (!e) return;
                                this.calculate(e.data, "VATGroupID", e); // Thực hiện tính toán ngay khi lúc insert vào lưới
                                e.component.refresh();
                            }}
                            onRowUpdating={(e) => {
                                if (!e) return;
                                const columnName = (Object.keys(e.newData))[0];
                                if (numberFormatEditName.includes(columnName) && e.oldData) { // Xữ lý NumberFormat vì editCellRender không v6 onEditorPreparing
                                    e.oldData[columnName] = e.newData[columnName];
                                    this.calculate(e.oldData, columnName, e); // Thực hiện tính toán những cột xài thư viện NumberFormat
                                    e.component.refresh();
                                }
                            }}
                            filterRow={{
                                visible: true,
                                applyFilter: 'auto'
                            }}
                            editing={{
                                mode: "cell",
                                refreshMode: "reshape",
                                allowUpdating: mode !== "view" && !(mode === "add" && saveSuccess),
                                texts: { confirmDeleteMessage: "" },
                                startEditAction: "click",
                            }}
                        >
                            <Column
                                width={50}
                                alignment={"center"}
                                allowEditing={false}
                                cellRender={(data) => data.rowIndex + 1}
                            />
                            <Column
                                width={120}
                                allowSorting={false}
                                allowEditing={false}
                                showInColumnChooser={false}
                                alignment={"center"}
                                visible={Config.isMobile}
                                caption={Config.lang("Hanh_dong")}
                                cellRender={(e) => this.renderActionHover(e)}
                            />
                            <Column
                                width={180}
                                allowSorting={true}
                                dataField={"InventoryID"}
                                caption={Config.lang("Ma_hang_hoa")}
                                allowEditing={isQuotationNoneCode === 0}
                            />
                            <Column
                                allowSorting={true}
                                dataField={"InventoryName"}
                                caption={Config.lang("Ten_hang_hoa")}
                                allowEditing={isQuotationNoneCode === 1}
                            />
                            <Column
                                width={100}
                                dataField={"UnitID"}
                                caption={Config.lang("DVT")}
                                allowEditing={false}
                            />
                            <Column
                                width={150}
                                allowEditing={false}
                                dataField={"UnitPriceOnD14"}
                                caption={Config.lang("Gia_goc")}
                                cellRender={this.renderNumberFormat}
                            />
                            <Column
                                dataField={"Notes"}
                                width={150}
                                caption={Config.lang("Dien_giai")}
                            />
                            <Column
                                width={270}
                                dataField={"PackingID"}
                                caption={Config.lang("Quy_cach_dong_goi")}
                            >
                                <Lookup
                                    allowClearing={true}
                                    valueExpr={"PackingID"}
                                    displayExpr={"PackingCustomName"}
                                    dataSource={dataCboPacking}
                                />
                            </Column>
                            <Column
                                width={150}
                                allowEditing={true}
                                dataField={"OUnitPrice"}
                                caption={Config.lang("DG_truoc_thue")}
                                cellRender={this.renderNumberFormat}
                                editCellRender={(e) => {
                                    return (
                                        <NumberFormat
                                            style={{ width: '100%' }}
                                            className={classes.numberFormatInput}
                                            decimalScale={!_.isEmpty(currencyObj) ? currencyObj.OriginalDecimal : 0}
                                            thousandSeparator={true}
                                            isNumericString={true}
                                            value={e.value}
                                            onValueChange={(c) => e.setValue(_.toNumber(c.value))}
                                        />
                                    )
                                }}
                            />
                            <Column
                                width={120}
                                dataType={"number"}
                                dataField={"Quantity"}
                                caption={Config.lang("So_luong")}
                                cellRender={this.renderNumberFormat}
                                editCellRender={(e) => {
                                    return (
                                        <NumberFormat
                                            style={{ width: '100%' }}
                                            className={classes.numberFormatInput}
                                            decimalScale={!_.isEmpty(dataDMFSetting) ? dataDMFSetting.D07_QuantityDecimals : 0}
                                            thousandSeparator={true}
                                            isNumericString={true}
                                            value={e.value}
                                            onValueChange={(c) => e.setValue(_.toNumber(c.value))}
                                        />
                                    )
                                }}
                            />
                            <Column
                                allowEditing={false}
                                dataField={"OAmountTmp"}
                                caption={Config.lang("Thanh_tien")}
                                cellRender={this.renderNumberFormat}
                            />
                            <Column
                                width={150}
                                allowEditing={false}
                                dataField={"CAmountTmp"}
                                caption={Config.lang("Thanh_tien_QD")}
                                cellRender={this.renderNumberFormat}
                            />
                            {/* Cột Động */}
                            {column}
                            {/* Cột Động */}
                            <Column
                                width={180}
                                allowEditing={false}
                                visible={columnDepentStatus}
                                dataType={"number"}
                                dataField={"AdjustedUnitPrice"}
                                caption={Config.lang("Don_gia_sau_DC")}
                                cellRender={this.renderNumberFormat}
                            />
                            <Column
                                width={180}
                                allowEditing={false}
                                dataField={"Amount"}
                                visible={columnDepentStatus}
                                caption={Config.lang("Thanh_tien_sau_DC")}
                                cellRender={this.renderNumberFormat}
                            />
                            <Column
                                width={180}
                                allowEditing={false}
                                dataField={"CAmount"}
                                visible={columnDepentStatus}
                                caption={Config.lang("Thanh_tien_sau_DC_QD")}
                                cellRender={this.renderNumberFormat}
                            />
                            <Column
                                width={180}
                                dataType={"number"}
                                dataField={"VATGroupID"}
                                caption={Config.lang("Nhom_thue")}
                                visible={vatDisplay === 1}
                            >
                                <Lookup
                                    allowClearing={true}
                                    valueExpr={"VATGroupID"}
                                    displayExpr={"VATGroupID"}
                                    dataSource={dataCboVATGroup}
                                />
                            </Column>
                            <Column
                                width={120}
                                allowEditing={false}
                                dataField={"VATRate"}
                                visible={vatDisplay === 1}
                                caption={`${Config.lang("Thue_suat")} %`}
                                format={"#,##0.##"}
                                dataType={"number"}
                            />
                            <Column
                                width={180}
                                allowEditing={false}
                                alignment={"right"}
                                dataField={"UnitPrice"}
                                visible={vatDisplay === 1}
                                caption={Config.lang("Don_gia_sau_thue")}
                                cellRender={this.renderNumberFormat}
                            />
                            <Column
                                width={180}
                                allowEditing={false}
                                visible={vatDisplay === 1}
                                dataField={"TotalAdjustedAmount"}
                                caption={Config.lang("Thanh_tien_sau_thue")}
                                cellRender={this.renderNumberFormat}
                            />
                            <Column
                                width={180}
                                allowEditing={false}
                                dataType={"number"}
                                visible={vatDisplay === 1}
                                dataField={"TotalAdjustedCAmount"}
                                caption={Config.lang("Thanh_tien_sau_thue_QD")}
                                cellRender={this.renderNumberFormat}
                            />
                            <Column
                                width={50}
                                fixed={true}
                                alignment={"right"}
                                fixedPosition={"right"}
                                allowEditing={false}
                                allowFixing={true}
                                visible={!Config.isMobile}
                                cellRender={(e) => this.renderActionHover(e)}
                            />
                            {/* SUM TOTAL Ở DƯỚI LƯỚI */}
                            {totalItem}
                            {/* SUM TOTAL Ở DƯỚI LƯỚI */}
                        </GridContainer>
                    </Col>
                </Row>
                <input
                    id={"attachmentW17F2000"}
                    multiple={"multiple"}
                    type={"file"}
                    className={"media_upload_input hide"}
                    onChange={this.upLoadAttach}
                />
            </div>
        )
    }
}

export default compose(connect((state) => ({
    cboStatus: state.w17f2000.cboStatus,
    dataCboDivision: state.w17f2000.dataCboDivision,
    dataCboTranType: state.w17f2000.dataCboTranType,
    dataCboEmployee: state.w17f2000.dataCboEmployee,
    dataCboDeliveryMethod: state.w17f2000.dataCboDeliveryMethod,
    dataCboPaymentTerm: state.w17f2000.dataCboPaymentTerm,
    dataCboPaymentMethod: state.w17f2000.dataCboPaymentMethod,
    dataCboCurrency: state.w17f2000.dataCboCurrency,
    dataCboStatus: state.w17f2000.dataCboStatus,
    dataCboVATGroup: state.w17f2000.dataCboVATGroup,
    dataDMFSetting: state.w17f2000.dataDMFSetting,
    dataShipAddress: state.w17f2000.dataShipAddress,
    dataCboPacking: state.w17f2000.dataCboPacking,
}), (dispatch) => ({
    w17f2000Actions: bindActionCreators(w17f2000Actions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    w18F1014Actions: bindActionCreators(w18F1014Actions, dispatch),
})))(W17F2000Page);

////////////
class PopupClose extends React.PureComponent {
    onHide = (reLoadStatus = false) => {
        if (this.props.onHide) this.props.onHide(reLoadStatus);
    };

    onSave = () => {
        const reason = document.getElementById("reasonPopupClose")?.value || "";
        if (this.props.onSave) this.props.onSave({ClosingReason: reason});
    };

    render() {
        const {data} = this.props;
        return (
            <PopupScroll
                title={Config.lang("Ly_do_dong_bao_gia")}
                show={true}
                className={"popup-size-40"}
                animation={true}
                onHide={this.onHide}>
                <div id={"frmPopupClose"} className={"page-container"}>
                    <Row>
                        <Col md={3} lg={3} sm={3} xs={12} >
                            <Label>{Config.lang("Nguoi_dong")}</Label>
                        </Col>
                        <Col md={9} sm={9} xs={12} lg={9}>
                            <input
                                className={"form-control"}
                                defaultValue={data?.ClosingUserName || ""}
                                readOnly
                            />
                        </Col>
                        <Col md={3} lg={3} sm={3} xs={12}>
                            <Label>{Config.lang("Ly_do_dong")}</Label>
                        </Col>
                        <Col md={9} sm={9} xs={12} lg={9}>
                            <textarea
                                rows={4}
                                className={"form-control"}
                                defaultValue={data?.ClosingReason || ""}
                                id={"reasonPopupClose"}
                            />
                        </Col>
                        <Col md={12} sm={12} xs={12} lg={12}>
                            <div className={"mgt4x mgb3x pull-right"} style={{ display: 'block' }}>
                                <Button
                                    size={"small"}
                                    color={'info'}
                                    startIcon={"Save"}
                                    label={Config.lang('Luu')}
                                    labelProps={{ style: { color: 'initial' } }}
                                    onClick={() => this.onSave()}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </PopupScroll>
        )
    }
}

PopupClose.propTypes = {
    data: PropTypes.any,
    onHide: PropTypes.func,
    onSave: PropTypes.func
};
